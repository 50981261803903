<template>
    <div class="app-container">
        <el-card class="box-card">
            <div slot="header" class="clearfix">
                <span>{{ $t('products.list.filters') }}</span>
                <span style="float: right">
                    <el-checkbox size="mini" v-model="advertisedProductsOnly"
                        >{{ $t('products.list.advertised-only') }}
                    </el-checkbox>
                    <el-checkbox size="mini" v-model="outdatedStock"
                        >{{ $t('products.list.outdated-stock') }}
                    </el-checkbox>
                    <el-checkbox size="mini" v-model="orderByMostViewed"
                        >{{ $t('products.list.most-viewed') }}&nbsp;
                    </el-checkbox>
                    <el-button type="primary" size="mini" icon="el-icon-check" plain @click.native="applyFilters">{{
                        $t('general.apply')
                    }}</el-button>
                    <el-button type="warning" size="mini" icon="el-icon-refresh" plain @click.native="resetFilters">
                        {{ $t('general.reset') }}</el-button
                    >
                </span>
            </div>
            <el-row :gutter="12">
                <el-col :xs="24" :sm="12" :md="12" :lg="6">
                    <div class="sub-title">{{ $t('products.list.search') }}</div>
                    <el-input
                        v-model="filters.keyword.value"
                        class="input-with-select"
                        :placeholder="$t('products.list.search-filter')"
                        @keyup.enter.native="applyFilters"
                    >
                        <el-button slot="append" icon="el-icon-search" @click.native="applyFilters(true, 'keyword')">
                        </el-button>
                    </el-input>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="6">
                    <div class="sub-title">{{ $t('products.list.category') }}</div>
                    <el-select
                        class="fullWidth"
                        v-model="filters.categories_id.value"
                        @change="applyFilters"
                        clearable
                        filterable
                        :placeholder="$t('products.list.category-filter')"
                    >
                        <el-option :label="totalCategories()" value="0"> </el-option>
                        <el-option
                            v-for="item in filtersList.categories"
                            :key="item.cid"
                            :label="itemWithCount(item)"
                            :value="item.cid"
                        >
                        </el-option>
                    </el-select>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="6">
                    <div class="sub-title">{{ $t('products.list.man') }}</div>
                    <el-select
                        class="fullWidth"
                        v-model="filters.manufacturer.value"
                        @change="applyFilters"
                        clearable
                        filterable
                        :placeholder="$t('products.list.man-filter')"
                    >
                        <el-option label="Toate" value="0"> </el-option>
                        <el-option
                            v-for="item in filtersList.manufacturers"
                            :key="item.mid"
                            :label="itemWithCount(item)"
                            :value="item.mid"
                        >
                        </el-option>
                    </el-select>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="6">
                    <div class="sub-title">Status</div>
                    <el-select
                        class="fullWidth"
                        v-model="filters.status.value"
                        @change="applyFilters"
                        clearable
                        filterable
                        :placeholder="$t('products.list.status-filter')"
                    >
                        <el-option
                            v-for="item in filtersList.status"
                            :key="item.sid"
                            :label="statusFilterText(item)"
                            :value="item.sid"
                        >
                        </el-option>
                    </el-select>
                </el-col>
                <!--				<el-col :xs="24" :sm="12" :md="12" :lg="6">-->
                <!--					<div class="sub-title">Status site</div>-->
                <!--					<el-select class="fullWidth" v-model="filters.status_site.value" @change="applyFilters" clearable-->
                <!--						filterable :placeholder="$t('products.list.status_site-filter')">-->
                <!--						<el-option v-for="item in filtersList.status_site" :key="item.sid"-->
                <!--							:label="statusFilterText(item)" :value="item.sid">-->
                <!--						</el-option>-->
                <!--					</el-select>-->
                <!--				</el-col>-->
                <el-col :xs="24" :sm="12" :md="12" :lg="6">
                    <div class="sub-title">{{ $t('general.model') }}</div>
                    <el-input
                        v-model="filters.products_model.value"
                        class="input-with-select"
                        :placeholder="$t('products.list.model-filter')"
                        @keyup.enter.native="applyFilters"
                    >
                        <el-button
                            slot="append"
                            icon="el-icon-search"
                            @click.native="applyFilters(true, 'products_model')"
                        ></el-button>
                    </el-input>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="6">
                    <div class="sub-title">{{ $t('products.list.intern-product') }}</div>
                    <el-input
                        v-model="filters.vendor_ext_id.value"
                        class="input-with-select"
                        :placeholder="$t('products.list.intern-filter')"
                        @keyup.enter.native="applyFilters"
                    >
                        <el-button
                            slot="append"
                            icon="el-icon-search"
                            @click.native="applyFilters(true, 'vendor_ext_id')"
                        ></el-button>
                    </el-input>
                </el-col>
            </el-row>
        </el-card>
        <div class="separator"></div>
        <ProductsFreeAdvertise />
        <div class="separator"></div>
        <el-card class="box-card" v-loading="listLoading">
            <div slot="header" class="clearfix">
                <span>{{ $t('products.list.product-list') }}</span>
                <span style="float: right">
                    <el-button type="info" size="mini" icon="el-icon-view" plain @click.native="toggleExpandRows">
                        {{ $t('products.list.toggleRowExpansion') }}</el-button
                    >
                    <el-button type="info" size="mini" icon="el-icon-tickets" plain @click.native="markAllAsActive">
                        {{ $t('products.list.markAsActive') }}</el-button
                    >
                    <el-button type="info" size="mini" icon="el-icon-tickets" plain @click.native="markAllAsInactive">
                        {{ $t('products.list.markAsInactive') }}</el-button
                    >
                    <el-button
                        type="warning"
                        size="mini"
                        icon="el-icon-download"
                        plain
                        @click.native="showExportProductsEmail"
                    >
                        {{ $t('products.list.export-list') }}</el-button
                    >
                    <el-button type="primary" size="mini" icon="el-icon-check" plain @click.native="saveProducts">{{
                        $t('products.list.save')
                    }}</el-button>
                </span>
            </div>
            <div class="center">
                <el-pagination
                    layout="prev, pager, next, total"
                    :current-page="currentPage"
                    :total="count"
                    :page-size="limit"
                    @current-change="changePage"
                >
                </el-pagination>
            </div>
            <div class="separator"></div>
            <div id="problemsContainer">
                <Box :type="'error'" :active="true" v-if="updateProblems.length > 0">
                    <div slot="box-body">
                        <span class="title-no-border">
                            <strong>{{ $t('products.list.problems') }}:</strong>
                        </span>
                        <ul class="rawUl">
                            <li v-for="(problem, index) in updateProblems" :key="index">
                                <span v-html="problem"></span>
                            </li>
                        </ul>
                    </div>
                </Box>
            </div>
            <div class="separator"></div>
            <el-table
                :data="produse"
                class="list-products-table"
                border
                style="width: 100%"
                @selection-change="handleSelectionChange"
                ref="productsTable"
                size="medium"
                :default-expand-all="expandAll"
            >
                <el-table-column type="selection" width="40px"> </el-table-column>
                <el-table-column type="expand">
                    <template slot-scope="props">
                        <el-row :gutter="10" class="expanded">
                            <el-col :xs="12" :sm="12" :md="8" :lg="8" :xl="8">
                                <div class="title">Detalii produs</div>
                                <p class="product-summary product-summary__brand">
                                    <strong>Brand: </strong>
                                    <span
                                        @click="applyCustomFilter('manufacturer', String(props.row.manufacturers_id))"
                                        >{{ props.row.manufacturer }}</span
                                    >
                                </p>
                                <p class="product-summary product-summary__category">
                                    <strong>Categorie: </strong>
                                    <span
                                        @click="
                                            applyCustomFilter('categories_id', getProductCategory(props.row.categs).id)
                                        "
                                        >{{ getProductCategory(props.row.categs).name }}</span
                                    >
                                </p>
                                <p>
                                    <b>{{ $t('products.list.client-price') }}:</b> {{ props.row.pretlei }}
                                </p>
                                <p>
                                    <b>{{ $t('products.list.old-price') }}:</b>
                                    {{ props.row.pret_vechi_cu_tva != null ? props.row.pret_vechi_cu_tva : '' }}
                                </p>
                                <p>
                                    <b>{{ $t('products.list.warranty-pf') }}:</b> {{ props.row.garantie }}
                                    <template
                                        v-if="props.row.warranty_pf > 0 && props.row.warranty_pf != props.row.garantie"
                                    >
                                        <b>{{
                                            '(' +
                                            $t('products.list.warranty_recommandation').replace(
                                                '%s%',
                                                props.row.warranty_pf
                                            ) +
                                            ')'
                                        }}</b>
                                        <el-tooltip
                                            class="item"
                                            effect="dark"
                                            :content="$t('products.list.sync')"
                                            placement="left"
                                        >
                                            <el-button
                                                icon="el-icon-refresh"
                                                class="no-button"
                                                :class="{
                                                    'spin-animation': syncingProducts[props.row.product_id + '_1'],
                                                }"
                                                @click.native="syncCategoryWarranty(props.row.product_id, 1)"
                                            ></el-button>
                                        </el-tooltip>
                                    </template>
                                </p>
                                <p>
                                    <b>{{ $t('products.list.warranty-pj') }}:</b> {{ props.row.garantie_pj }}
                                    <template
                                        v-if="
                                            props.row.warranty_pj > 0 && props.row.warranty_pj != props.row.garantie_pj
                                        "
                                    >
                                        <b>{{
                                            '(' +
                                            $t('products.list.warranty_recommandation').replace(
                                                '%s%',
                                                props.row.warranty_pj
                                            ) +
                                            ')'
                                        }}</b>
                                        <el-tooltip
                                            class="item"
                                            effect="dark"
                                            :content="$t('products.list.sync')"
                                            placement="left"
                                        >
                                            <el-button
                                                icon="el-icon-refresh"
                                                class="no-button"
                                                :class="{
                                                    'spin-animation': syncingProducts[props.row.product_id + '_2'],
                                                }"
                                                @click.native="syncCategoryWarranty(props.row.product_id, 2)"
                                            ></el-button>
                                        </el-tooltip>
                                    </template>
                                </p>
                                <p>
                                    <b>{{ $t('products.list.last-5views') }}:</b>
                                    {{ 'undefined' != typeof props.row.total_views ? props.row.total_views : 0 }}
                                </p>
                                <p
                                    v-for="(item, i) in props.row.date_views"
                                    :key="i"
                                    v-if="'undefined' != typeof props.row.date_views"
                                >
                                    <b>{{ $t('products.list.views-on') }} {{ item.data }}: </b>
                                    {{ item.products_views }}
                                </p>
                                <p v-if="'undefined' !== typeof props.row.extprice" style="color: firebrick">
                                    <b>{{ $t('products.list.extprice') }}:</b>
                                    {{ 'undefined' != typeof props.row.extprice ? props.row.extprice : 0 }} RON
                                </p>

                                <p
                                    class="product-summary product-summary__dimensiuni"
                                    v-if="
                                        dimensiuniProdusValide(props.row.cat_dimensions) ||
                                        dimensiuniProdusValide(props.row.dimensions)
                                    "
                                >
                                    <strong>{{ $t('products.list.dimensiuni') }}:</strong>
                                    <template v-if="dimensiuniProdusValide(props.row.dimensions)">
                                        <span style="color: blue; cursor: default">
                                            {{ props.row.dimensions.latime }} x {{ props.row.dimensions.inaltime }} x
                                            {{ props.row.dimensions.adancime }}</span
                                        >
                                    </template>
                                    <template v-else-if="dimensiuniProdusValide(props.row.cat_dimensions)">
                                        <span style="color: red; cursor: default">
                                            {{ props.row.cat_dimensions.latime }} x
                                            {{ props.row.cat_dimensions.inaltime }} x
                                            {{ props.row.cat_dimensions.adancime }}</span
                                        >
                                    </template>
                                </p>
                                <p
                                    class="product-summary product-summary__livrabil_locker"
                                    v-if="lockereActive(props.row)"
                                >
                                    <strong>{{ $t('products.list.livrabil_locker') }}:</strong>

                                  <span v-if="esteLivrabilLocker(props.row)">
                                      {{ hasRecentUpdate(props.row) ? $t('general.yes') : $t('products.list.norecentstock') }}
                                  </span>
                                  <span v-else>
                                      {{ $t('general.no') }}
                                  </span>
                                </p>
                            </el-col>
                            <el-col :push="2" :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                                <div class="title">{{ $t('products.list.sugestii_produs') }}</div>
                                <div v-if="props.row.suggestions.length < 1" class="sugg-container">
                                    <i class="el-icon-success success"></i>&nbsp;
                                    {{ $t('products.list.fara_sugestii_produs') }}
                                </div>
                                <div class="sugg-container" v-else>
                                    <p v-for="(s, i) in props.row.suggestions" :key="i">
                                        <span class="sugg">
                                            <span class="sugg-icon"><i class="el-icon-warning"></i></span>
                                            <span class="sugg-message"
                                                ><span class="sugg-title">{{ s.title }}:</span> {{ s.message }}</span
                                            >
                                        </span>
                                    </p>
                                </div>
                            </el-col>
                        </el-row>
                    </template>
                </el-table-column>
                <el-table-column label="" width="60px" align="center">
                    <template slot-scope="scope">
                        <el-popover
                            placement="right"
                            width="400"
                            trigger="hover"
                            v-if="typeof scope.row.main_image !== 'undefined' && scope.row.main_image !== ''"
                        >
                            <img
                                onerror="this.src='https://s1.cel.ro/includes/templates/cel/images/no_picture.gif'"
                                class="imgPopup"
                                :src="scope.row.main_image"
                                alt=""
                            />
                            <img
                                onerror="this.src='https://s1.cel.ro/includes/templates/cel/images/no_picture.gif'"
                                class="imgPreview"
                                slot="reference"
                                :src="scope.row.main_image"
                                alt=""
                            />
                        </el-popover>
                    </template>
                </el-table-column>
                <el-table-column :label="$t('general.name')">
                    <template slot-scope="scope">
                        <i
                            class="el-icon-goods in-campaign"
                            :title="
                                atob(scope.row.id_campanie) +
                                ': ' +
                                convertTimestamp(scope.row.start) +
                                ' - ' +
                                convertTimestamp(scope.row.end)
                            "
                            @click="goToCampaign(scope.row.id_campanie)"
                            v-if="scope.row.end >= new Date().getTime() / 1000"
                        ></i>
                        <a target="_blank" :href="gotoAddress(scope.$index, produse)">{{
                            produse[scope.$index].products_name
                        }}</a>
                        <div class="product-summary product-summary__main-supplier">
                            <strong>Detinator documentatie:</strong>
                            <span class="no-link">{{ produse[scope.$index].main_supplier }}</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="products_model" :label="$t('general.model')" width="150px"> </el-table-column>
                <el-table-column :label="$t('products.list.sup-price')" width="120px">
                    <template slot-scope="scope">
                        <el-input
                            size="mini"
                            v-model="produse[scope.$index].pretf"
                            @keyup.native="toggleSelection(scope.$index)"
                        ></el-input>
                    </template>
                </el-table-column>
                <el-table-column prop="pret_vechi" label="Pret vechi (fara TVA)" width="100px">
                    <template slot-scope="scope">
                        <el-input
                            size="mini"
                            v-model="produse[scope.$index].pret_vechi"
                            @keyup.native="toggleSelection(scope.$index)"
                        ></el-input>
                    </template>
                </el-table-column>
                <el-table-column prop="stoc" label="Stoc" width="100px">
                    <template slot-scope="scope">
                        <el-input
                            :min="0"
                            :max="5000"
                            size="mini"
                            v-model="produse[scope.$index].stoc"
                            @keyup.native="toggleSelection(scope.$index)"
                        ></el-input>
                    </template>
                </el-table-column>
                <el-table-column label="Status" width="120px">
                    <template slot-scope="scope" ref="block">
                        <el-select
                            v-model="produse[scope.$index].status"
                            size="mini"
                            class="fullWidth"
                            @change="toggleSelection(scope.$index)"
                        >
                            <el-option
                                v-for="item in pactivsts"
                                :key="item.valoare"
                                :label="item.nume"
                                :value="item.valoare"
                            >
                            </el-option>
                        </el-select>
                    </template>
                </el-table-column>
                <el-table-column prop="promotion" width="140px" :label="$t('products.list.weight')">
                    <template slot-scope="scope">
                        <el-select
                            size="mini"
                            class="fullWidth"
                            v-model="produse[scope.$index].promotion"
                            @change="autoSubmitPromotion(scope.$index)"
                            :disabled="scope.row.principal == 0"
                        >
                            <el-option key="0" :value="0" :label="$t('products.list.options.normal')"></el-option>
                            <el-option key="1" :value="1" :label="$t('products.list.options.promotion')"></el-option>
                            <el-option key="2" :value="2" :label="$t('products.list.options.clearing')"></el-option>
                        </el-select>
                    </template>
                </el-table-column>
                <el-table-column prop="procesare_in" label="Timp livrare (zile)" width="100px">
                    <template slot-scope="scope">
                        <el-input
                            size="mini"
                            v-model="produse[scope.$index].procesare_in"
                            @keyup.native="toggleSelection(scope.$index)"
                        ></el-input>
                    </template>
                </el-table-column>
                <!-- <el-table-column prop="garantie" label="Garantie PF" width="100px">
            </el-table-column>
            <el-table-column prop="garantie_pj" label="Garantie PJ" width="100px">
            </el-table-column> -->
                <el-table-column :label="$t('products.list.bonus')" width="65px">
                    <template slot-scope="scope">
                        <el-button
                            :type="checkIfBonus(produse[scope.$index].bonus.length)"
                            icon="el-icon-star-off"
                            size="mini"
                            @click="handleBonus(scope.$index)"
                        ></el-button>
                    </template>
                </el-table-column>
                <el-table-column :label="$t('general.action')" width="240px" fixed="right">
                    <template slot-scope="scope">
                        <el-row :gutter="12">
                            <el-col :span="6">
                                <el-tooltip
                                    class="item"
                                    effect="dark"
                                    :content="
                                        productIsAdvertised(produse[scope.$index])
                                            ? 'Scoate produsul de la promovare'
                                            : 'Adauga produsul la promovare'
                                    "
                                    placement="top-start"
                                >
                                    <el-button
                                        @click="toggleAdvertise(scope.$index)"
                                        size="mini"
                                        type="warning"
                                        icon="el-icon-trophy"
                                        :plain="!productIsAdvertised(produse[scope.$index])"
                                    ></el-button>
                                </el-tooltip>
                            </el-col>
                            <el-col :span="6">
                                <el-tooltip
                                    class="item"
                                    effect="dark"
                                    content="Cloneaza produsul"
                                    placement="top-start"
                                >
                                    <router-link :to="{ path: duplicateAddress(scope.$index, produse) }">
                                        <el-button size="mini" type="info" icon="el-icon-document" plain></el-button>
                                    </router-link>
                                </el-tooltip>
                            </el-col>
                            <el-col :span="6">
                                <el-tooltip
                                    class="item"
                                    effect="dark"
                                    content="Modifica produsul"
                                    placement="top-start"
                                >
                                    <router-link :to="{ path: editAddress(scope.$index, produse) }">
                                        <el-button size="mini" type="primary" icon="el-icon-edit" plain> </el-button>
                                    </router-link>
                                </el-tooltip>
                            </el-col>

                            <el-col :span="6">
                                <el-tooltip class="item" effect="dark" content="Sterge produsul" placement="top-start">
                                    <el-button
                                        size="mini"
                                        type="danger"
                                        icon="el-icon-delete"
                                        plain
                                        @click="deleteProduct(scope.$index)"
                                    >
                                    </el-button>
                                </el-tooltip>
                            </el-col>
                        </el-row>
                    </template>
                </el-table-column>
            </el-table>
            <div class="separator"></div>
            <div class="center">
                <el-pagination
                    layout="prev, pager, next, total"
                    :current-page="currentPage"
                    :total="count"
                    :page-size="limit"
                    @current-change="changePage"
                >
                </el-pagination>
            </div>
        </el-card>

        <el-dialog :title="$t('products.list.export-list-title')" :visible.sync="modalExportList" class="dialog-sm">
            <el-row :gutter="20">
                <el-col :span="24">
                    <el-select
                        class="fullWidth"
                        v-model="exportListEmail"
                        filterable
                        :placeholder="$t('products.list.export-list-email')"
                    >
                        <el-option v-for="item in exportEmails" :key="item" :label="item" :value="item" selected>
                        </el-option>
                    </el-select>
                </el-col>
                <el-col :span="24">
                    <el-select
                        @change="exportProducts"
                        class="fullWidth"
                        v-model="exportListType"
                        :placeholder="$t('products.list.export-list-type')"
                    >
                        <el-option :label="$t('products.list.export-list-complete')" value="export_complet"></el-option>
                        <el-option :label="$t('products.list.export-list-filtred')" value="export_filtre"></el-option>
                    </el-select>
                </el-col>
            </el-row>
        </el-dialog>

        <el-dialog title="Bonus" :visible.sync="bonusModal" width="50%" @close="resetBonusSearch">
            <span>
                <div class="sub-title">{{ $t('products.list.search-products') }}</div>
                <el-input v-model="bonusSearch" class="input-with-select" @keyup.enter.native="searchBonus">
                    <el-button slot="append" icon="el-icon-search" @click.native="searchBonus"></el-button>
                </el-input>
                <div v-if="bonusResult.length > 0">
                    <div class="separator"></div>
                    <div class="sub-title">{{ $t('products.list.search-results') }}</div>
                    <el-table :data="bonusResult" stripe style="width: 100%" border size="mini">
                        <el-table-column prop="products_name" :label="$t('name')"> </el-table-column>
                        <el-table-column
                            prop="products_model"
                            width="180px"
                            :label="$t('products.list.products-model')"
                        >
                        </el-table-column>
                        <el-table-column :label="$t('products.list.actions')" width="70px" fixed="right">
                            <template slot-scope="scope">
                                <el-button
                                    type="primary"
                                    @click="addBonus(scope.$index, bonusResult)"
                                    size="mini"
                                    icon="el-icon-plus"
                                ></el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <div v-if="bonusList.length > 0">
                    <div class="separator"></div>
                    <div class="sub-title">{{ $t('products.list.bonus-list') }}</div>
                    <el-table :data="bonusList" stripe style="width: 100%" border size="mini">
                        <el-table-column prop="name" :label="$t('general.name')"> </el-table-column>
                        <el-table-column prop="model" width="180px" :label="$t('products.list.products-model')">
                        </el-table-column>
                        <el-table-column :label="$t('general.actions')" width="70px" fixed="right">
                            <template slot-scope="scope">
                                <el-button
                                    type="danger"
                                    @click="deleteBonusProduct(scope.$index, bonusList)"
                                    size="mini"
                                    icon="el-icon-delete"
                                ></el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <div v-else>
                    <div class="separator"></div>
                    <div class="sub-title">{{ $t('products.list.bonus-list') }}</div>
                    <div class="center">
                        <strong>{{ $t('products.list.no-bonus') }}</strong>
                    </div>
                </div>
            </span>
            <span slot="footer" class="dialog-footer">
                <el-button @click="bonusModal = false" plain>{{ $t('general.close') }}</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
const BinPacking3D = require('binpackingjs').BP3D;
const Box = () => import('vue-info-box-element');
const ProductsFreeAdvertise = () => import('@/components/ProductsFreeAdvertising/Index.vue');
import { EventBus } from '@/utils/Bus.js';
import {
    getProducts,
    saveProducts,
    getFilters,
    getBonus,
    addBonus,
    deleteBonus,
    exportProducts,
    // updateStatus,
    updatePromotion,
    markAllProductsAsActive,
    markAllProductsAsInactive,
    updateStockAndPrice,
    syncCategoryWarranty,
    deleteProduct,
    toggleProductAdvertise,
} from '@/api/produse';
import { getContact } from '@/api/cont';
import has from 'lodash.has';
import reduce from 'lodash.reduce';
import isEqual from 'lodash.isequal';
import { mapState, mapGetters } from 'vuex';
import { call, get } from 'vuex-pathify';

import { debugPromise } from '@/utils/index';
export default {
    components: {
        Box,
        ProductsFreeAdvertise,
    },
    props: ['filterQuery'],
    data() {
        return {
            syncingProducts: {},
            listLoading: false,
            bonusModal: false,
            produse: [],
            origProducts: [],
            monede: [
                {
                    id: 'RON',
                    nume: 'RON',
                },
                {
                    id: 'EUR',
                    nume: 'EURO',
                },
                {
                    id: 'USD',
                    nume: 'USD',
                },
            ],
            pactivsts: [
                {
                    valoare: 1,
                    nume: this.$t('products.list.active'),
                },
                {
                    valoare: 0,
                    nume: this.$t('products.list.inactive'),
                },
            ],
            promooptions: [
                {
                    valoare: 0,
                    nume: this.$t('products.list.all'),
                },
                {
                    valoare: 1,
                    nume: this.$t('products.list.promo'),
                },
                {
                    valoare: 2,
                    nume: this.$t('products.list.lichid'),
                },
                {
                    valoare: 3,
                    nume: this.$t('products.list.resig'),
                },
                {
                    valoare: 4,
                    nume: this.$t('products.list.refurb'),
                },
            ],
            filters: {
                manufacturer: {
                    type: 'select',
                    value: '',
                },
                status: {
                    type: 'select',
                    value: '',
                },
                status_site: {
                    type: 'select',
                    values: '',
                },
                keyword: {
                    type: 'input',
                    value: '',
                },
                categories_id: {
                    type: 'select',
                    value: '',
                },
                vendor_ext_id: {
                    type: 'input',
                    value: '',
                },
                start: {
                    type: 'page',
                    value: 0,
                },
                products_model: {
                    type: 'input',
                    value: '',
                },
            },
            filtersList: {},
            searchText: '',
            lastSearch: '',
            currentBonusList: '',
            finalFilters: null,
            limit: 25,
            pages: 0,
            ccpage: 0,
            count: 0,
            forceCount: false,
            currentPage: 1,
            multipleSelection: [],
            bonusSearch: '',
            bonusList: [],
            bonusResult: [],
            bonusProductSelected: {},
            orderByMostViewed: false,
            outdatedStock: false,
            advertisedProductsOnly: false,
            modalExportList: false,
            exportListEmail: '',
            exportListType: '',
            exportEmails: [],
            updateProblems: [],
            locker: {
                prelucrate: 0,
                curieri: [],
                activ: 0,
            },
        };
    },
    methods: {
        toggleExpandRows() {
            this.$refs.productsTable.data.forEach((item, key) => {
                this.$refs.productsTable.toggleRowExpansion(this.produse[key]);
            });

            // this.expandAll = !this.expandAll

            this.$store.dispatch('app/SetProductsList', !this.expandAll);
        },
        atob(string) {
            return window.atob(string);
        },
        convertTimestamp(timestamp) {
            const date = new Date(timestamp * 1000);

            return (
                (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) +
                '/' +
                (date.getMonth() < 9 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1)
            );
        },
        applyCustomFilter(filter, value) {
            this.filters[filter].value = value;
            this.applyFilters();
        },
        getProductCategory(categ) {
            for (const n in categ) {
                return {
                    id: n,
                    name: categ[n],
                };
            }
        },
        addBonus(index, list) {
            const selected = list[index];
            addBonus({
                model: this.bonusProductSelected.products_model,
                bonus: selected.products_model,
            })
                .then((res) => {
                    this.getBonus();
                })
                .catch((e) => {
                    this.$reportToSentry(e, {
                        extra: {
                            fn: 'addBonus',
                            params: {
                                model: this.bonusProductSelected.products_model,
                                bonus: selected.products_model,
                            },
                        },
                    });
                    debugPromise('addBonus', e);
                });
        },
        getBonus() {
            getBonus({
                model: this.bonusProductSelected.products_model,
            })
                .then((res) => {
                    this.bonusList = res.message;
                })
                .catch((e) => {
                    this.$reportToSentry(e, {
                        extra: {
                            fn: 'getBonus',
                            params: {
                                model: this.bonusProductSelected.products_model,
                            },
                        },
                    });
                    debugPromise('getBonus', e);
                });
        },
        searchBonus() {
            if (this.bonusSearch < 1) return false;
            this.bonusResult = [];
            getProducts({
                search: this.bonusSearch,
                start: 0,
                limit: 1000,
                forceCount: false,
            })
                .then((res) => {
                    this.bonusResult = res.message.data;
                })
                .catch((e) => {
                    this.$reportToSentry(e, {
                        extra: {
                            fn: 'searchBonus',
                            params: {
                                search: this.bonusSearch,
                                start: 0,
                                limit: 1000,
                                forceCount: false,
                            },
                        },
                    });
                    debugPromise('searchBonus', e);
                });
        },
        handleBonus(index) {
            const bonus = this.produse[index].bonus;
            if (bonus.length > 0) this.bonusList = bonus;
            this.bonusProductSelected = this.produse[index];
            this.bonusModal = true;
        },
        deleteBonusProduct(index, list) {
            const selected = list[index];
            deleteBonus({
                model: this.bonusProductSelected.products_model,
                bonus: selected.model,
            })
                .then((res) => {
                    this.getBonus();
                })
                .catch((e) => {
                    this.$reportToSentry(e, {
                        extra: {
                            fn: 'deleteBonus',
                            params: {
                                model: this.bonusProductSelected.products_model,
                                bonus: selected.model,
                            },
                        },
                    });
                    debugPromise('deleteBonusProduct', e);
                });
        },
        resetBonusSearch() {
            this.bonusSearch = '';
            this.bonusList = [];
            this.bonusResult = [];
            this.bonusProductSelected = {};
            this.readProducts();
        },
        autoSubmitPromotion(index) {
            const data = {
                model: this.produse[index].products_model,
                promotion: this.produse[index].promotion,
            };
            updatePromotion(data)
                .then((res) => {})
                .catch((e) => {
                    this.$reportToSentry(e, {
                        extra: {
                            fn: 'autoSubmitPromotion',
                            params: data,
                        },
                    });
                    console.log(e);
                });
        },
        readProducts() {
            const params = {
                start: this.filters.start.value,
                limit: this.limit,
                search: this.searchText,
                forceCount: this.forceCount,
                filters: JSON.stringify(this.finalFilters),
                orderByMostViewed: this.orderByMostViewed,
                outdatedStock: this.outdatedStock,
                advertisedProductsOnly: this.advertisedProductsOnly ? 1 : 0,
            };
            this.listLoading = true;
            getProducts(params)
                .then((res) => {
                    this.listLoading = false;
                    if ('undefined' == typeof res) {
                        this.$router
                            .push({
                                name: 'Profil public',
                            })
                            .catch(() => {});
                        return;
                    }

                    if (
                        res == null ||
                        typeof res.message === 'undefined' ||
                        (Array.isArray(res.message) && res.message.length < 1)
                    ) {
                        this.count = 0;
                        this.produse = [];
                        return false;
                    }
                    if (!has(res.message, 'data')) {
                        this.count = 0;
                        this.produse = [];
                        this.origProducts = [];
                        return false;
                    }

                    this.produse = res.message.data
                        .map((item) => {
                            item.status = parseInt(item.status);
                            return item;
                        })
                        .filter((item) => item.products_model != null);
                    this.origProducts = JSON.parse(JSON.stringify(res.message.data));
                    this.filters.start.value = res.message.start;
                    this.limit = parseInt(res.message.limit);
                    this.currentPage = Math.ceil(this.filters.start.value / this.limit) + 1;
                    if (typeof res.message.count !== 'undefined') this.count = parseInt(res.message.count);
                    else this.count = 0;
                })
                .catch((e) => {
                    this.listLoading = false;
                    this.$reportToSentry(e, {
                        extra: {
                            fn: 'getProducts',
                            params,
                        },
                    });
                    debugPromise('getProducts', e);
                });
        },
        readFilters(readProducts = false) {
            getFilters({
                filters: this.finalFilters,
            })
                .then((res) => {
                    if (
                        res == null ||
                        typeof res.message === 'undefined' ||
                        (Array.isArray(res.message) && res.message.length < 1)
                    )
                        return false;
                    this.filtersList = res.message;

                    // Add missing statuses
                    if (this.filtersList.status.filter((item) => item.sid == 0).length == 0) {
                        this.filtersList.status.push({
                            count: 0,
                            sid: '0',
                        });
                    }
                    if (this.filtersList.status.filter((item) => item.sid == 1).length == 0) {
                        this.filtersList.status.push({
                            count: 0,
                            sid: '1',
                        });
                    }
                    if (this.filtersList.status_site.filter((item) => item.sid == 0).length == 0) {
                        this.filtersList.status_site.push({
                            count: 0,
                            sid: '0',
                        });
                    }
                    if (this.filtersList.status_site.filter((item) => item.sid == 1).length == 0) {
                        this.filtersList.status_site.push({
                            count: 0,
                            sid: '1',
                        });
                    }
                    if (!this.locker.prelucrate) {
                        if (this.couriers.length && Object.keys(this.couriers_boxes).length) {
                            this.prelucrareDateCurieri();
                        } else {
                            this.getAccountCouriers()
                                .then(() => {
                                    this.getCouriersBoxes()
                                        .then(() => {
                                            this.prelucrareDateCurieri();
                                        })
                                        .catch((e) => {
                                            this.$reportToSentry(e, {
                                                extra: {
                                                    fn: 'getCouriersBoxes',
                                                },
                                            });
                                        });
                                })
                                .catch((e) => {
                                    this.$reportToSentry(e, {
                                        extra: {
                                            fn: 'getAccountCouriers',
                                        },
                                    });
                                });
                        }
                    }
                    if (readProducts) this.readProducts();
                })
                .catch((e) => {
                    this.$reportToSentry(e, {
                        extra: {
                            fn: 'getProducts',
                            params: {
                                filters: this.finalFilters,
                            },
                        },
                    });
                    debugPromise('readFilters', e);
                });
        },
        applyFilters(fromSearch, field) {
            if (fromSearch == true && typeof field !== 'undefined' && this.filters[field].value < 3) return false;

            this.finalFilters = {};
            for (const prop in this.filters) {
                if (fromSearch == true && field == prop) {
                    this.finalFilters[prop] = this.filters[prop].value;
                } else if (fromSearch !== true) {
                    this.finalFilters[prop] = this.filters[prop].value;
                }
            }
            this.formatFilters();
            this.readProducts();
            this.readFilters();
            if (typeof this.updateQuery === 'function') this.updateQuery();
        },
        resetFilters() {
            this.filters = {
                manufacturer: {
                    type: 'select',
                    value: '',
                },
                status: {
                    type: 'select',
                    value: '',
                },
                keyword: {
                    type: 'input',
                    value: '',
                },
                categories_id: {
                    type: 'select',
                    value: '',
                },
                vendor_ext_id: {
                    type: 'input',
                    value: '',
                },
                products_model: {
                    type: 'input',
                    value: '',
                },
                start: {
                    type: 'page',
                    value: '',
                },
            };
            this.applyFilters();
        },
        formatFilters() {
            this.filters.start.value = 0;
            this.limit = 25;
            this.currentPage = 1;

            for (const i in this.filters) {
                if (
                    i !== 'keyword' &&
                    (i === 'status' || parseInt(this.filters[i].value) !== 0) &&
                    this.filters[i].value !== ''
                ) {
                    this.finalFilters[i] = [this.filters[i].value];
                }
            }
        },
        itemWithCount(item) {
            return item.name + ' ' + '[' + item.count + ']';
        },
        statusFilterText(item) {
            if (parseInt(item.sid) === 0) return 'Inactive [' + item.count + ']';
            else return 'Active [' + item.count + ']';
        },
        gotoAddress(index, list) {
            return this.isDemo
                ? 'http://' + list[index].link.replace(/www?\.cel\.ro/i, 'test.cel.ro')
                : 'https://' + list[index].link;
        },
        editAddress(index, list) {
            let endpoint = '/produse/produs/' + btoa(list[index].products_model);
            if (list[index].vendor_ext_id != null && list[index].vendor_ext_id != '') {
                endpoint = `${endpoint}/${btoa(unescape(encodeURIComponent(list[index].vendor_ext_id)))}`;
            }
            return endpoint;
        },
        duplicateAddress(index, list) {
            let endpoint = '/produse/clone/' + btoa(list[index].products_model);
            if (list[index].vendor_ext_id != null && list[index].vendor_ext_id != '') {
                endpoint = `${endpoint}/${btoa(unescape(encodeURIComponent(list[index].vendor_ext_id)))}`;
            }
            return endpoint;
        },
        changePage(page) {
            this.filters.start.value = page * this.limit - this.limit;
            this.currentPage = page;
            this.readProducts();
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        toggleSelection(index) {
            const checkIfSelected = this.multipleSelection.filter(
                (item) => this.produse[index].products_model === item.products_model
            );
            if (checkIfSelected.length < 1) {
                this.$refs.productsTable.toggleRowSelection(this.produse[index]);
            }
        },
        async syncCategoryWarranty(productId, warrantyType) {
            this.$set(this.syncingProducts, productId + '_' + warrantyType, true);
            return syncCategoryWarranty({ id: productId, tip: warrantyType }).then((_) => {
                return this.readProducts();
            });
        },
        saveProducts() {
            this.updateProblems = [];
            const params = this.getFinalParams();
            if (params.length < 1) {
                this.sbMsg({
                    type: 'warn',
                    message: this.$t('products.list.save-products-error'),
                });
                return false;
            }

            updateStockAndPrice({
                products: params,
            }).then((res) => {
                if (res == null || res.error == null || res.error == 1) return false;
                if (res.message.bad_list.length > 0) {
                    for (const problem of res.message.bad_list) {
                        this.updateProblems.push(`[<b>${problem.products_model}</b>] ${problem.msg}`);
                    }
                }
                if (res.message.updated > 0) {
                    if (res.message.updated < params.length) {
                        this.sbMsg({
                            type: 'warn',
                            message: 'Nu toate produsele au fost actualizate. Verifica lista cu probleme.',
                        });
                    } else {
                        this.sbMsg({
                            type: 'success',
                            message: 'Produsele au fost actualizate',
                        });
                    }
                    this.$refs.productsTable.clearSelection();
                    this.readProducts();
                    this.readFilters();
                } else {
                    this.sbMsg({
                        type: 'warn',
                        message: 'Au fost gasite probleme la actualizarea produselor. Verificati lista.',
                    });
                    return false;
                }
            });
        },
        getFinalParams() {
            const finalSave = [];

            for (const i in this.multipleSelection) {
                const original = this.origProducts.filter(
                    (item) => item.products_model === this.multipleSelection[i].products_model
                );
                if (original.length < 1) continue;

                const differences = reduce(
                    this.multipleSelection[i],
                    (result, value, key) => {
                        return isEqual(value, original[0][key]) ? result : result.concat(key);
                    },
                    []
                );
                if (differences.length > 0) {
                    const finalObject = {
                        products_model: this.multipleSelection[i].products_model,
                        pretf: this.multipleSelection[i].pretf,
                        vendor_ext_id:
                            this.multipleSelection[i].vendor_ext_id != null && this.multipleSelection[i].vendor_ext_id
                                ? this.multipleSelection[i].vendor_ext_id
                                : 0,
                    };
                    for (const x in differences) {
                        finalObject[differences[x]] = this.multipleSelection[i][differences[x]];
                    }
                    finalSave.push(finalObject);
                }
            }
            return finalSave;
        },
        checkIfBonus(bonusLength) {
            return bonusLength > 0 ? 'success' : '';
        },
        totalCategories() {
            let total = 0;
            for (const i in this.filtersList.categories) {
                total += this.filtersList.categories[i].count;
            }

            return 'Toate [' + total + ']';
        },
        async showExportProductsEmail() {
            try {
                const res = await getContact();
                this.exportEmails = res.message.contact;

                for (const e in this.exportEmails) {
                    this.exportListEmail = this.exportEmails[e];
                    break;
                }
            } catch ($err) {}
            this.modalExportList = true;
            this.exportListType = '';
        },
        async exportProducts() {
            try {
                await this.$confirm(
                    'Confirmati cererea de export? Veti primi lista pe email in aproximativ 15 minute.',
                    'Atentie',
                    {
                        confirmButtonText: 'Da, sunt sigur',
                        cancelButtonText: 'Nu, nu vreau',
                        type: 'warning',
                    }
                );
                const params = {
                    start: this.filters.start.value,
                    limit: this.limit,
                    search: this.searchText,
                    forceCount: this.forceCount,
                    filters: this.exportListType == 'export_filtre' ? JSON.stringify(this.finalFilters) : '{}',
                    orderByMostViewed: this.orderByMostViewed,
                    outdatedStock: this.outdatedStock,
                    email: this.exportListEmail,
                };
                exportProducts(params).then((res) => {
                    if (!has(res, 'message') || !has(res.message, 'success')) return false;
                    this.sbMsg({
                        type: 'success',
                        message:
                            'Cererea de export a fost inregistrata. Imediat ce procesul se va incheia veti primi pe email lista de produse.',
                    });
                    this.modalExportList = false;
                    this.exportListType = '';
                });
            } catch ($err) {
                this.modalExportList = false;
                this.exportListType = '';
            }
        },
        setFiltersFirstTime() {
            this.finalFilters = {};
            for (const i in this.filters) {
                if (
                    i !== 'keyword' &&
                    (i === 'status' || parseInt(this.filters[i].value) !== 0) &&
                    this.filters[i].value !== ''
                ) {
                    this.finalFilters[i] = [this.filters[i].value];
                } else if (i === 'keyword' && this.filters[i].value !== '') {
                    this.finalFilters[i] = this.filters[i].value;
                }
            }
        },
        async markAllAsActive() {
            try {
                await this.$confirm(
                    'Sunteti sigur ca doriti marcarea tuturor produselor ca active?',
                    'Activare produse',
                    {
                        confirmButtonText: 'Da, sunt sigur',
                        cancelButtonText: 'Nu, nu vreau',
                        type: 'warning',
                    }
                );

                await markAllProductsAsActive();
                this.readFilters();
            } catch (e) {
                console.log(e);
            }
        },
        async markAllAsInactive() {
            try {
                await this.$confirm(
                    'Sunteti sigur ca doriti marcarea tuturor produselor ca inactive?',
                    'Dezactivare produse',
                    {
                        confirmButtonText: 'Da, sunt sigur',
                        cancelButtonText: 'Nu, nu vreau',
                        type: 'warning',
                    }
                );

                await markAllProductsAsInactive();
                this.readFilters();
            } catch (e) {
                console.log(e);
            }
        },
        goToCampaign(name) {
            this.$router
                .push({
                    path: '/produse/campanie/' + name,
                })
                .catch(() => {});
        },
        composedStatus(index) {
            return this.produse[index].status == 1 && this.produse[index].products_status == 1 ? 1 : 0;
        },
        setStatusForProduct(index, value) {
            this.produse[index].status = value;
        },
        dimensiuniProdusValidate(row) {
            if (
                (typeof row.dimensions.adancime != 'undefined' || typeof row.cat_dimensions.adancime != 'undefined') &&
                (row.dimensions.adancime > 0 || row.cat_dimensions.adancime > 0) &&
                (typeof row.dimensions.inaltime != 'undefined' || typeof row.cat_dimensions.inaltime != 'undefined') &&
                (row.dimensions.inaltime > 0 || row.cat_dimensions.inaltime > 0) &&
                (typeof row.dimensions.latime != 'undefined' || typeof row.cat_dimensions.latime != 'undefined') &&
                (row.dimensions.latime || row.cat_dimensions.latime) > 0
            ) {
                return true;
            }

            return false;
        },
        lockereActive(row) {
            return this.locker.activ === 1 && row.products_virtual == 0;
        },
        hasRecentUpdate(row) { //cheat gpt
          const candDate = new Date(row.cand); // Convertește string-ul într-un obiect Date
          const now = new Date(); // Data curentă
          const days30Ago = new Date(now.setDate(now.getDate() - 30)); // Data de acum 30 de zile

          return candDate > days30Ago; // Verifică dacă este mai recent de 30 de zile
        },
        esteLivrabilLocker(row) {
            if (
                this.lockereActive(row) &&
                this.dimensiuniProdusValidate(row) &&
                row.offer_status == 1 &&
                row.products_status == 1 &&
                parseInt(row.stoc) > 0 &&
                this.productFitToBox(row)
            ) {
                return true;
            }

            return false;
        },
        prelucrareDateCurieri() {
            this.locker.prelucrate = 1;

            this.couriers.forEach((curier) => {
                if (curier.enableLockers === 1) this.locker.activ = 1;
                if (!this.locker.curieri.includes(curier.courier_id)) this.locker.curieri.push(curier.courier_id);
            });
        },
        dimensiuniProdusValide(dimensiuni) {
            if (
                typeof dimensiuni.adancime != 'undefined' &&
                dimensiuni.adancime > 0 &&
                typeof dimensiuni.inaltime != 'undefined' &&
                dimensiuni.inaltime > 0 &&
                typeof dimensiuni.latime != 'undefined' &&
                dimensiuni.latime > 0
            ) {
                return true;
            }

            return false;
        },
        getAccountCouriers: call('account/GetAccountCouriers'),
        getCouriersBoxes: call('account/GetCouriersBoxes'),
        productFitToBox(row) {
            const { Item, Bin, Packer } = BinPacking3D;

            let lockereDisponibile = [];

            this.locker.curieri.forEach((elCurierID) => {
                if (typeof this.couriers_boxes[elCurierID] != 'undefined') {
                    this.couriers_boxes[elCurierID].boxes.forEach((elBoxC) => {
                        lockereDisponibile.push(
                            new Bin(elBoxC.name, elBoxC.width, elBoxC.height, elBoxC.depth, elBoxC.maxWeight)
                        );
                    });
                }
            });

            if (lockereDisponibile.length < 1) return false;

            let incapeInBox = false;
            lockereDisponibile.forEach((arLocker) => {
                if (!incapeInBox) {
                    let PickupPoint = new Packer();

                    PickupPoint.addBin(arLocker);

                    PickupPoint.addItem(
                        new Item(
                            row.products_name || 'Produs',
                            row.dimensions.latime || row.cat_dimensions.latime,
                            row.dimensions.inaltime || row.cat_dimensions.inaltime,
                            row.dimensions.adancime || row.cat_dimensions.adancime,
                            parseFloat(row.real_weight) || 1
                        )
                    );
                    PickupPoint.pack();

                    incapeInBox = PickupPoint.unfitItems.length === 0;
                }
            });

            return incapeInBox;
        },
        async deleteProduct(index) {
            const product = this.produse[index];
            if (product.can_delete == 0) {
                return this.sbMsg({
                    type: 'error',
                    message: 'Produsul nu poate fi sters deoarece exista comenzi pentru acesta.',
                });
            }
            const response = await this.$confirm(
                'Confirmati stergerea produsului? Aceasta actiune este ireversibila.',
                'Stergere produs',
                {
                    confirmButtonText: 'Da, sunt sigur',
                    cancelButtonText: 'Nu, nu vreau',
                    type: 'warning',
                }
            )
                .then(() => true)
                .catch(() => false);

            if (!response) return;

            const params = {
                products_model: product.products_model,
                vendor_ext_id: product.vendor_ext_id,
            };

            try {
                await deleteProduct(params);

                this.sbMsg({
                    type: 'success',
                    message: 'Produsul a fost sters',
                });
                return this.readProducts();
            } catch (e) {
                this.$reportToSentry(e, {
                    extra: {
                        fn: 'deleteProduct',
                        params,
                    },
                });
                console.log(e);
                this.sbMsg({
                    type: 'warn',
                    message: 'Eroare la stergerea produsului',
                });
            }
        },
        productIsAdvertised(product) {
            return product.is_advertised == 1;
        },
        async toggleAdvertise(index) {
            const product = this.produse?.[index];
            if (!product) return;

            const isAdvertised = this.productIsAdvertised(product) ? 0 : 1;

            const response = await this.$confirm(
                isAdvertised ? 'Doriti sa promovati acest produs?' : 'Doriti sa scoateti acest produs din promotie?',
                'Confirmare',
                {
                    confirmButtonText: 'Da, sunt sigur',
                    cancelButtonText: 'Nu, nu vreau',
                    type: 'warning',
                }
            )
                .then(() => true)
                .catch(() => false);

            if (!response) return;

            const params = {
                pid: product.product_id,
                status: isAdvertised,
            };

            try {
                await toggleProductAdvertise(params);

                this.sbMsg({
                    type: 'success',
                    message: 'Operatiune efectuata cu succes',
                });
                this.readProducts();
                EventBus.$emit('product-advertised');
            } catch (e) {
                this.$reportToSentry(e, {
                    extra: {
                        fn: 'toggleAdvertise',
                        params,
                    },
                });
            }
        },
    },
    computed: {
        couriers: get('account/couriers@list'),
        couriers_boxes: get('account/couriers@boxes'),
        ...mapGetters({
            isDemo: 'user/isDemo',
        }),
        ...mapState({
            expandAll: (state) => state.app.expandProductsList,
        }),
        // composedStatus:{
        //     set: function(index){
        //         this.value = window.vm.produse[index].status == 1 && (window.vm.produse[index].products_status == 1 ? 1 : 0)
        //     },
        //     get: function(){
        //         return this.value
        //     }
        // }
    },
    mounted() {
        this.setFiltersFirstTime();
        this.readFilters(true);

        window.vm = this;
    },
};
</script>
<style lang="scss" scoped>
.list-products-table {
    .expanded {
        font-size: 13px;

        p {
            margin: 8px;
        }
    }
}

.product-summary {
    font-size: 13px;

    span:not(.no-link) {
        color: #ff601d;
        cursor: pointer;
    }
}

.sugg-container {
    .success {
        color: #67c23a;
    }

    .sugg {
        display: table;
        width: 100%;

        .sugg-icon {
            display: table-cell;
            vertical-align: top;
            width: 1%;
            padding-right: 5px;
            color: #ff601d;
            line-height: 17px;
        }

        .sugg-message {
            display: table-cell;
            line-height: 17px;

            .sugg-title {
                font-weight: 600;
            }
        }
    }
}

.in-campaign {
    color: #ff7a51;
    font-weight: bold;
    font-size: 150%;
    margin-right: 7px;
}

.only-principals {
    font-size: 11px;
    color: red;
}

.el-checkbox + .el-checkbox {
    margin-left: 15px;
}

.spin-animation {
    animation-name: spin;
    animation-duration: 2000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

@keyframes spin {
    from {
        transform: rotate(360deg);
    }
    to {
        transform: rotate(0deg);
    }
}

.no-button {
    color: gray;
    background: transparent;
    border-color: transparent;
    padding: 0px;
}
</style>
